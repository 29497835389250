:root{
    --background: #F9F7FD; 
    --type1:#667085;
    --type2:#515251;
    --purple: #2F945C;
    --deep-blue:#0F042F;
  }
  ::before,
  ::after{
      margin: 0;
      padding: 0;
      box-sizing: border-box;
  }
  @font-face {
    font-family: Nunito1; 
    src: url('../../LocalFont/Nunito-Italic-VariableFont_wght.ttf');
  }
  @font-face {
    font-family: Nunito2;
    src: url('../../LocalFont/Nunito-VariableFont_wght.ttf');
  }
  
  .body{
    width: 100%; 
    /* background-image: url('../../Images/LoginBackground.png'); */
    display: flex;
    background-color: #F7FDFA;;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Nunito2;
    background-size: cover;
    height: 100vh;
  }
  .main{
    max-width: 1216px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  h1, h2, h3, h4, h5, h6{
    color: #101828; 
  }
  .Login{
    display: flex;
    width: 100%;
    max-width: 360px;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
    /* gap: 22px; */
  }
  .LoginHeader{
    text-align: center;
  }
  .LoginHeader h2{
    font-size: 40px;
    font-weight: 600;
  }
  .LoginHeader p{
    font-size: 18px;
    color: #667085;
  }
  form div{
    margin-bottom: 10px;
  }
  form label{
    font-size: 16px;
    font-weight: 700;
    color: #344054;
  }
  form .formInput{
    width: 100%;
    min-width: 360px;
    display: flex;
    padding: 10px 14px;
    border: none;
    outline:none;
    border:1px solid  #D0D5DD;
    background-color: #F2E5FC;
    border-radius: 8px;
    /* align-self: stretch; */
  }
  .section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  .section span{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
  .section span p{
    margin: 0;
  }
  .section a{
    text-decoration: none;
    color: #2F945C;
    font-size: 14px;
    font-weight: 600;
  }

  .link{
    color: #2F945C;
    cursor: pointer;
  }
  .link :hover{
    color: #195a36;
  }
  .SignInBtn{
    background-color:  #2F945C;
    width: 100%;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    font: 600;
    color: white;
    transition: ease 0.6s;
    box-shadow: none;
  }
  .SignInBtn1{
    background-color:  #fff;
    width: 100%;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    font: 600;
    color: #2F945C;
    transition: ease 0.6s;
    box-shadow: none;
    border: 2px solid #2F945C;
    margin-top: 20px;
  }
  .SignInBtn:hover{
    background-color: transparent;
    border: 2px solid #2F945C;
    color: #2F945C;
  }
  .SignInBtn1:hover{
    background-color: #2F945C;
    border: 2px solid #fff;
    color: #fff;
  }
  .dntHvAcct{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    gap: 5px;
  }
  .dntHvAcct span{
    border: none;
    outline: none;
    background-color: transparent;
    color: #2F945C;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
  .dntHvAcct p{
    margin: 0;
  }

  @media(max-width:800px){
    .LoginHeader h2{
      font-size: 20px;
      margin-bottom: 2px;
      /* font-weight: 600; */
    }
    .LoginHeader p{
      font-size: 12px;
      /* margin-bottom: 2px; */
      /* font-weight: 600; */
    }
    .Login form label{
      font-size: 12px;
    }
    /* .Login form{ */
      /* margin: 0 20px 0 15px; */
      /* padding-left: 10px; */
      /* padding-right: 25px; */
    /* } */
  }