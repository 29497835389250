
.reportBody{
    display: flex;
    justify-content: center;
    align-items: center;
   margin: 1rem 0;
    background-color: rgb(216, 211, 211);
    font-family: Arial, Helvetica, sans-serif;
}
h1, h2, h3, h4, h5, h6{
    margin: 10px;
    margin-left: 0;
}
.a42{
    width: 35.7cm;
    min-height: 21cm;
    margin: 0 auto; /* Set margin-top to 0 */
    padding: 1cm;
    background-color: white;
    padding: 0.5in;
    page-break-after: always; 
}
.header{
    width: 100%;
    text-align: center;
}
.header p{
    width: 100%;
    text-align: center;
    /* margin-top: 30px; */
}
.table2{
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.table2 td, .table2 th{
    padding-left: 10px;
}
.table2 th{
    /* color: balck; */
    border-bottom: 2px solid black;
    font-size: 15px;
    padding-top: 10px;
    text-align: left;
}
.table2 td{
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    padding-top: 10px;
} 
.table2 td:nth-child(1){
    padding: 10px 0 0 0 !important;
    width: 80px !important;
}
.table2 td:nth-child(2){
    padding: 10px 0 0 0 !important;

}
.table2 td:nth-child(3){
    /* width: 25%; */
    text-align: right;
}

.table2 td:nth-child(5){
    text-align: left;
}
.table2 td:nth-child(6){
    text-align: right;
}

.table2 td:nth-child(6){

}

.table2 td:nth-child(4){

}
.table2 td:nth-child(7),
.table2 td:nth-child(8){
    text-align: right;
}

.table2 .bold{
    /* border-top: 2px solid black; */
    font-weight: bold;
    margin-top: 1rem;
}
.table2 th:nth-child(6){
    font-weight: bold;
}
.table2 th{
    /* border-left: 2px solid black; */
}
.table2 tr:nth-child(1),
.table2 tr:nth-child(2){
    /* background: red; */
    /* border: 2px solid black; */
}
/* </style> */
.table2 .totalSec{
 /* text-align: right; */
 border-bottom: 2px solid black;
 border-top: 2px solid black;
}