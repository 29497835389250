@font-face {
    font-family: Millik;
    src: url('../../LocalFont/Millik.ttf');
  }
  @font-face {
    font-family: Nunito1; 
    src: url('../../LocalFont/Nunito-Italic-VariableFont_wght.ttf');
  }
  @font-face {
    font-family: Nunito2;
    src: url('../../LocalFont/Nunito-VariableFont_wght.ttf');
  }
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .body{
    background-color: #F7FDFA;
    overflow: hidden;
  }
  
.pricingHero {
    margin-top: 10rem;
    justify-content: center;
    align-items: center; 
}
.pricebtn {
    color: #6941C6;
    font-size: 14px;
    background-color: #D1FADF;
    border:none;
    padding: 5px 10px;
    border-radius: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    color: black;
    margin-top: 5rem;
    text-align: center;
 }
 .content div{
    display: flex;
    justify-content: center;
}
.pherotext{
    font-size: 20px;
    font-weight: 400;
    color: #667085;
    padding-bottom: 0.5rem;
    font-family: Nunito2;
}
 
.pricing{
    position: relative;
}
.mostpop {
    font-weight: 500;
    font-size: 14px;
    color:#2F945C;
    z-index: 20;
    position: absolute;
    left: 24rem;
    top: -2rem;
    display: flex;
}
.pricingcards {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin: 2rem 0  6rem 0;
}
.basic {
    background-color: #F7FDFA;
    width: 359.33px;
    /* height: 510px; */
    text-align: center;
    border-radius: 16px;
    box-shadow: -1px 4px 6px  rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    border-top: 2px solid rgba(16, 24, 40, 0.03);
}

    



.herotext {
    font-size: 48px;
    font-weight: 600;
    font-family: Nunito2;
}


.lowrherobtn {
    margin-bottom: 3rem;
    font-size: 18px;
    font-weight: 600;
    justify-content: space-between;
    margin-right: 15px;
    font-family: Nunito2;
}

/* .signupbtn {
    background-color: #7F56D9;
    color: white;
    width: 119px;
    height: 60px;
    border-radius: 8px;
    border: none;
    margin-left: 15px;
    
} */

/* .signupbtn a{ 
    text-decoration: none;
    color: white;
} */

.demobtn {
    background-color: white;
    border: 1.5px solid #D0D5DD;
    border-radius: 8px;
    width: 141px;
    height: 60px;
    font-weight: 600;
    color: #344054;
}

.arrowRight {
    width: 18px;
    height: 18px;
    
}

.Demo {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    margin-bottom: 2px;
}
.bodybg {
    width: 1512px;
    height: 525px;
    z-index: 5;
}
.myh1{
    padding-top: 40px;
    font-weight: 600;
    font-size: 40px;
}
.myp {
    font-size: 20px;
    font-weight: 600;
    margin-top: 16px;
    margin-bottom: -0.5px;
}
.my2p {
    font-size: 16px;
    font-weight: 400;
    margin-top: -4px;
    color: #667085;
}
.Check {
    width: 24px;
    height: 24px;
    margin-right: 12PX;
}
.mysubp {
    text-align: left;
    margin-left: 35px; 
}
.myhp {
    margin-top: 32px;
    margin-bottom: 40px;
}
.mibtn{
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    background-color: #2F945C;
    border: none;
    color: white;
    width: 295.33px;
    height: 48px;
    margin-bottom: 1rem;
}

.mibtn a {
    text-decoration: none;
    color: white;
}




p .mostpop {
margin-bottom: 15px;
}

.Handdrawn {
    width: 61.96px;
    height: 50px; 
}

.pricing {
    /* position: relative; */
}

@media(max-width:800px){

  .pricingcards {
    flex-direction: column;
    margin: 0;
  }
  .herotext {
    font-size: 24px;
}
.content{
    padding: 20px;
}
.content p{
    padding-top: 10px;
}
  .mostpop { 
    z-index: 20;
    position: absolute;
    right: 25%;
    top: 5.5%;
    }
    .mostpop {
        display: none;
    }
    
}
