:root{
  --background: #F9F7FD; 
  --type1:#667085;
  --type2:#515251;
  --purple: #7F56D9;
  --Green:#0F042F;
  --Green:#2F945C;
}
::before,
::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
@font-face {
  font-family: Millik;
  src: url('../../LocalFont/Millik.ttf');
}
@font-face {
  font-family: Nunito1; 
  src: url('../../LocalFont/Nunito-Italic-VariableFont_wght.ttf');
}
@font-face {
  font-family: Nunito2;
  src: url('../../LocalFont/Nunito-VariableFont_wght.ttf');
}

.body{
  width: 100%; 
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Nunito2;
}
/* .companyName{
  color: var(--Green);
  font-family: Millik;
  font-weight: 500;
  font-size: 25px;
} */
.companyName{
  font-size: 18px;
  color: black;
  font-weight: 400;
  /* width: 25%; */
  margin-top: -8px;
  margin-bottom: 0px;
  font-family: roboto;
}
.mainMenuStyle{
  justify-content: center;
  align-items: center;
  width: 100%; 
  gap:70px;
}
.main{
  max-width: 1216px;
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6{
  color: #101828;
}
.NavContainer{
  width: 100%;
  background-color:  #F7FDFA;
  border-bottom: 1px solid #EAECF0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  margin-top: 4.7rem;
}
.Navbar{
  width: 100%;
  width: 1216px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  position: relative;
}
.navMenu{
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  gap: 40px;
  margin-right: 40px;
  margin-bottom: 0 !important;
}
.Navbar a{ 
  text-decoration: none;
  font: 16px;
  font-weight: 600;
  /* color: #13092A; */
  transition:  0.3s;
}
.Navbar a:hover{
  color: var(--Green);
  /* text-shadow: -1px 1px 2px 20px; */
  text-shadow: -1px 2px 2px rgba(0, 0, 0, 0.5);
}
.topNavGroupbtns{
  display:flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  gap: 20px;
  margin: 10px 0 0 250px;
}
.topNavGroupbtns button{
  border-radius: 12px;
  display: flex;
  padding: 13px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: ease 0.3s;
  width: 115px;
  border: 1px solid var(--Green);
  outline: none;
}
.LoginBtn{
  background-color: transparent;
}
.SignUpBtn{
  background-color: var(--Green);
}
.SignUpBtn a{
  color: white;
}
.LoginBtn a{
  color: var(--Green);
  font-weight: 700;
}
.SignUpBtn a:hover,
.LoginBtn a:hover{
  color: var(--Green);
}
.SignUpBtn:hover,
.LoginBtn:hover{
  transform: scale(1.05);
  background-color: transparent;
  border: 2px solid var(--Green);
  color: var(--Green);
}

.Logo {
  width: 112px;
  height: 32.26px;
  /* margin-top: 15px; */
}
.navbarToggler{
  display: none;
  background-color: transparent;
  border: none;
  outline: none;
}
@media(max-width:800px){
  .topLogoTime{
    display: none;
}
.navbar-custom-menu navbar
  .body, .main{
    width: 100%;
    overflow: hidden;
  }
  .NavContainer{
    width: 100%;
  }
  .navbarToggler{
    display: block;
    font-size: 34px;
  }
  .Navbar{
    width: 100%;
  }
  .navMenu{
    position: absolute;
    width: 100%;
    flex-direction: column;
    left: 0;
    top: 65px;
    padding: 20px 0;
    background-color: white;
    gap: 20px;
    /* display: ${isNavMenuVisible ? 'flex' : 'none'}; */
    /* display: none; */
    /* display: ${props => (props.isVisible ? 'flex' : 'none')}; */
  }
  .topNavGroupbtns{
    flex-direction: column;
    margin: 0;
  }
}
