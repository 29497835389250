.route-not-found-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: white;
}

.not-found-image {
    width: 30%;
    height: auto;
    margin-bottom: 20px;
}

.not-found-text {
    font-size: 24px;
    color: #333;
}
