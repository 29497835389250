/* display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" */
/* style={{marginLeft: 500}} */
.infoFotCont{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    gap: 20px;
}
.pref{
    margin-left: 500px;

}


@media(max-width:800px){
    .infoFotCont{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .pref{
        margin: 0;
    }
    .copy{
        font-size: 10px;
        text-align: center;
    }
    .infoFotCont :nth-child(2){
        /* font-size: 10px; */
        /* text-align: center; */
        color: green;
        margin-top: 10px;
    }
    .infoFotCont .copy a{
        font-size: 10px !important;
    }
}